<template>
  <div class="production">
    <div style="background: no-repeat center center / cover" :style="{ backgroundImage: 'url(' + require('@/assets/breadcumb.jpg') + ')' }" class="breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="breadcumb-title">{{ langContent.production }} </h2>
            <ul class="breadcumb-menu">
              <li><router-link to="/">Anasayfa</router-link></li>
              <li>{{ langContent.production }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <template v-for="(item,index) in production">
      <div v-if="index%2===0" class="help-center-area mt--100">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-flex flex-center">
              <div class="hlp-center-content text-lg-right pr-5">
                <h4>{{ item.title }}</h4>
                <p>
                 {{item.desc}}
                </p>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="hlp-center-thumb">
                <img :src="item.img" :alt="item.title">
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/vs1.png" alt="vector-shape1" class="vector-about">
      </div>
      <div v-else class="help-center-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="hlp-center-thumb">
                <img :src="item.img" :alt="item.title">
              </div>
            </div>
            <div class="col-lg-6 d-flex flex-center">
              <div class="hlp-center-content">
                <h4>{{ item.title }}</h4>
                <p>
                  {{item.desc}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <img alt="vector-shape1" class="vector-shape1" src="@/assets/vs1.png">
      </div>
    </template>

  </div>
</template>

<script>
import axiosCustom from "../../axiosCustom";

export default {
  data() {
    return {
      production:[],
      langContent:[]
    }
  },
  created() {
    axiosCustom
        .get('langfix')
        .then(response => {
          this.langContent = response.data[0]
          document.title = 'Delta Plastik | '+response.data[0].production
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('production')
        .then(response => {
          this.production = response.data
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods: {
  }
}
</script>
